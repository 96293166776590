<template>
    <div v-if="me" class="container">
        <StackRouterHeaderBar :title="'근로소득자'" :show-title="true" />
        <main class="wrapper">
            <div class="title m-b-8">인증방법을 선택해주세요</div>
            <div class="required m-b-20">
                <div class="menu" v-for="menu in menus" :key="menu.id" @click="onClickMenu(menu)">
                    <span class="f-medium c-black f-16" v-html="menu.name" />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: 'CertificateEmployeeIncomePage',
    data: () => ({
        extend: false,
        disabled: false,
    }),
    mounted() {
        this.init()
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        menus() {
            return [
                {
                    id: 1,
                    name: '원천징수영수증',
                    route: '',
                },
                {
                    id: 2,
                    name: '소득금액증명원',
                    route: '',
                },
                {
                    id: 3,
                    name: '지급명세서',
                    route: '',
                },
            ]
        },
        verifyStatus() {
            const badges = this.$store.getters.verificationBadges
            if (!badges || !badges.length) return false
            console.log(this.$store.getters.verifyStatus)
            return this.$store.getters.verifyStatus
        },
        myBadges() {
            return this.$store.getters.myVerificationBadges || []
        },
        declined() {
            return this.$store.getters.declined
        },
        certiSubmitted() {
            const requiredVeri = [13, 14, 15]
            const hasAllCerties = requiredVeri.every(r => {
                return (this.$store.getters.myVerificationBadges || []).some(b => b.verification_badge_id === r)
            })
            return hasAllCerties
        },
    },
    methods: {
        async init() {
            if (!this.verifyStatus) {
                await this.$store.dispatch('loadVerificationBadges')
            }
            await this.$store.dispatch('loadMyVerificationBadges')
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFF9EF',
                },
            })
        },
        async save() {
            try {
                await this.$store.dispatch('loadMyVerificationBadges')
                this.$store.dispatch('loadMe')
                this.$store.dispatch('loadSettings')
            } catch (e) {
                console.log(e)
            }
        },
        onClickCertMethod() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://vbproduct.notion.site/ac8a5e85d1e04f0e92a45f879fd289a1`,
            })
        },
        onClickMenu(menu) {
            const verificationBadge = this.$store.getters.verificationBadges.find(badge => badge.name === '소득 인증')
            this.$modal.custom({
                component: 'ModalCertificate',
                options: {
                    verificationBadge,
                    menuId: menu.id,
                },
            })
        },
        back() {
            this.$stackRouter.pop()
        },
    },
    created() {
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.save()
        this.$unregisterBackHandler()
    },
}
</script>

<style lang="scss" scoped>
.bottom-border {
    border-bottom: 1px solid $grey-02;
}
.container {
    color: #111111;

    .wrapper {
        padding: 24px 16px;

        .check-declined {
            @include between;
            padding: 8px;
            background: $bg-danger;
            border-radius: 8px;
            margin-bottom: 24px;

            .left {
                @include center;
            }
        }

        .title {
            @include spoqa-f-bold;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            padding: 30px;
        }
        .menu {
            border: 1px solid $grey-04;
            padding: 25px;
            margin-bottom: 10px;
            border-radius: 8px;
            font-size: 16px;
            color: black;
            @include between;

            .right {
                font-size: 12px;
                @include center;
            }
        }
        .mandate {
            width: calc(100vw - 36px);
            margin: 0 auto;
            border: 1px solid $grey-04;
            border-radius: 8px;
            padding: 18px 12px;
            font-size: 14px;

            .header {
                @include between;
                font-size: 14px;
                color: black;
                margin-bottom: 10px;
            }
            .menu {
                .mandate-item {
                    @include between;
                    margin-bottom: 8px;
                    color: black;
                }
            }
            .notice-wrapper {
                color: $grey-08;
                line-height: 20px;
            }
        }
    }
}
</style>
